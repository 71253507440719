import React from "react";

const MadeByLink = () => (
  <a
    href="https://superbees.com"
    target="_blank"
    rel="noopener noreferrer"
  >
    superbees
  </a>
)

export default MadeByLink

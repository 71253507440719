import React from 'react'
import Main from 'components/Main'
import Footer from 'components/Footer'
import 'assets/App.scss'

const App = () => (
  <>
    <Main />
    <Footer />
  </>
)

export default App;

import React, { useEffect, useState } from 'react'
import classNames from "classnames";

const TimeItIs = () => {
  const addBodyClass = className => document.body.classList.add(className);
  const removeBodyClass = className => document.body.classList.remove(className);

  const [time, setTime] = useState(new Date())

  const zeroPad = (num, places) => String(num).padStart(places, '0')

  useEffect(() => {
    setInterval(() => setTime(new Date()), 1000)
  }, [])

  const isAfter20 = time.getHours() >= 20;
  if (isAfter20) {
    addBodyClass('darken')
  }

  return <time>
      <span className={classNames('number', 'hours', {'darken': isAfter20} )}>{zeroPad(time.getHours(), 2)}</span>
      <span className={classNames('sep', {'darken': isAfter20} )}>:</span>
      <span className={classNames('number', 'minutes', {'darken': isAfter20} )}>{zeroPad(time.getMinutes(), 2)}</span>
      <span className={classNames('sep', {'darken': isAfter20} )}>:</span>
      <span className={classNames('number', 'seconds', {'darken': isAfter20} )}>{zeroPad(time.getSeconds(), 2)}</span>
    </time>
}

export default TimeItIs
